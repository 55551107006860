import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { images } from './imageresource';
import { Typography } from '@material-ui/core';


//生成从minNum到maxNum的随机数
function randomNum(minNum, maxNum) {
    switch (arguments.length) {
        case 1:
            return parseInt(Math.random() * minNum + 1, 10);
            break;
        case 2:
            return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
            break;
        default:
            return 0;
            break;
    }
}
var i = randomNum(0, images.length - 1);
let backgroundImage = (process.env.NODE_ENV === 'development' ? "http://" : "https://") + "linju1020.gitee.io/randomimage" + '/images/' + images[i];

const styles = {
    login: {
        main: {
            backgroundImage: `url(${backgroundImage})`,
        },
        card: {
            padding: '5px 0 15px 0', position: 'relative', overflow: 'inherit'
        },
    },
    form: {
        button: {
            height: '3em',
        },
    },
};

const MyLoginForm = withStyles(styles.form)(LoginForm);

const MyLogin = (props) => (
    <Login {...props} >
        <>
            <MyLoginForm />
            <Typography
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                    bottom: -35,
                    color: '#FFF',
                    opacity: 0.7
                }}
                variant='caption'>
                Copyright 2022 双鑫食品 All Rights Reserved
            </Typography>
        </>
    </Login>
);

export default withStyles(styles.login)(MyLogin);