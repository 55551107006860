import * as React from 'react';
import { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, FunctionField, useMutation, useQueryWithStore } from 'react-admin';
import { useNotify, useRefresh, useAuthProvider } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import { UpLoadFile } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
import { apiUrl, getThumborPic } from '../global';
import { httpClient } from '../dataProvider';
//import ResetOrderNum from './Order_ResetOrderNum';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button as CButton, TextField as CTextField, Chip, IconButton } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import qs from 'qs';

{/* 
  import { OrderList, OrderCreate, OrderEdit, OrderShow } from './components/Order';

  <Resource name="CMSOrder" list={OrderList} create={OrderCreate} edit={OrderEdit} show={OrderShow} />
  <Resource name="CMSOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: 'Order',
  },
  table: {
    id: 'id',
    OrderNumber: '订单号',
    OrderStatus: '订单状态',
    UserId: '会员',
    Name: '收货人',
    Mobile: '手机号',
    Province: '省',
    City: '市',
    Area: '区',
    Addr: '地址',
    LogRemark: '会员备注',
    TotalCount: '总件数',
    TotalPrice: '总金额',
    CreateTime: '创建时间',
    PayTime: '支付时间',
    ReceiptTime: '收货时间',
    FinishTime: '完成时间',
    CancelTime: '取消时间',
    Discount: '优惠金额',
    Discount: '优惠金额',
    Discount: '优惠金额',
    UserAccount: '会员账号',
    UserNickName: '会员昵称',
    PrintStatus: '打印状态',
    PrintTime: '打印时间',
  }
};

//分页列表页面
export const OrderList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        {/* <TextInput label="ID" source="id" alwaysOn resettable /> */}
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <CreatePrint {...props} />
        <CancelWaitPrint {...props} />
        {/* <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton {...props} /><br /> */}
        <ShowButton {...props} /><br />
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
        {/* <CancelWaitPrint {...props} /> */}
      </div>
    );
  }

  const OrderStatus = ({ record: { OrderStatus } }) => {
    // console.log('OrderStatus', OrderStatus)
    let color = '#FFF';
    let backgroundColor = '#0080008a';
    switch (OrderStatus) {
      case '待付款': break;
      case '待出库': backgroundColor = '#0080008a'; break;
      case '待配送': backgroundColor = '#0000ff8a'; break;
      case '待收货': color = ''; backgroundColor = '#ff00008a'; break;
      case '已完成': color = ''; backgroundColor = '#5a5a5aab'; break;
      case '已取消': color = ''; backgroundColor = '#5a5a5a47'; break;
    }
    return (
      <Chip size="small" label={OrderStatus} style={{ color, backgroundColor }} />
    )
  }

  const CancelWaitPrint = ({ selectedIds }) => {
    console.log('selectedIds', selectedIds)
    const [mutate, { loading }] = useMutation();
    const refresh = useRefresh();
    const notify = useNotify();
    const [doing, setDoing] = React.useState(false);
    const [result, setResult] = React.useState('');
    React.useEffect(() => {

    }, [])

    const clickHandle = () => {
      setDoing(true);
      httpClient(apiUrl + `/Admin/CMSOrder2/CancelWaitPrint`, {
        method: 'POST',
        // contentType: 'application/x-www-form-urlencoded',
        body: JSON.stringify({ selectedIds }),
      }).then(({ headers, json }) => {
        if (json.Code == 1) {
          notify('移除成功');
          refresh();
        }
        else
          notify(json.Message);
      }).finally(() => {
        setDoing(false);
      })
    }

    if (loading) return <CircularProgress />;

    return (
      <CButton style={{ color: 'red' }} component="span" onClick={clickHandle}>
        从打印列表移除
      </CButton>
    );
  }

  const CreatePrint = ({ selectedIds }) => {
    console.log('selectedIds', selectedIds)
    const [mutate, { loading }] = useMutation();
    const refresh = useRefresh();
    const notify = useNotify();
    const [doing, setDoing] = React.useState(false);
    const [result, setResult] = React.useState('');
    React.useEffect(() => {

    }, [])

    const clickHandle = () => {
      setDoing(true);
      httpClient(apiUrl + `/Admin/CMSOrder2/AddPrintTask`, {
        method: 'POST',
        // contentType: 'application/x-www-form-urlencoded',
        body: JSON.stringify({ selectedIds }),
      }).then(({ headers, json }) => {
        if (json.Code == 1) {
          notify('发货单创建成功，请自行打印');
          refresh();
          DownPrintPdf(json.Result);
        }
        else
          notify(json.Message);
      }).finally(() => {
        setDoing(false);
      })
    }

    const DownPrintPdf = ({ url, fileName, printNumber }) => {
      var anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = `${apiUrl}${url}`;
      anchor.target = "_blank";
      anchor.download = `${fileName}`;
      anchor.click();
    }

    if (loading) return <CircularProgress />;

    return (
      <CButton color='primary' component="span" onClick={clickHandle}>
        创建&导出发货单
      </CButton>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={<AssetBulkActionButtons />} exporter={false} empty={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        <FunctionField label={'订单/状态'} render={(record) => {
          return (
            <>
              <TextField label={useTxtData.table.OrderNumber} source="OrderNumber" /><br />
              {/* <TextField label={useTxtData.table.OrderStatus} source="OrderStatus" /> */}
              <OrderStatus record={record} />
            </>
          )
        }} />
        <FunctionField label={'会员'} render={() => {
          return (
            <>
              <TextField source="UserAccount" /><br />
              <TextField source="UserNickName" />
            </>
          )
        }} />
        <FunctionField label={'收货地址'} render={() => {
          return (
            <>
              <TextField label={useTxtData.table.Name} source="Name" />
              <TextField label={useTxtData.table.Mobile} source="Mobile" />
              <br />
              <TextField label={useTxtData.table.City} source="City" />
              <TextField label={useTxtData.table.Area} source="Area" />
              <TextField label={useTxtData.table.Addr} source="Addr" />
            </>
          )
        }} />
        <TextField label={'总件数'} source="TotalCount" sortable={false} />
        {/* <FunctionField label={'订单金额'} render={(record) => {
          return (
            <>
              ¥<TextField source="TotalPrice" /><br />
              {record['Discount'] && record['Discount'] != 0 && <div style={{ fontSize: 10, color: 'red' }}>惠：¥<TextField source="Discount" /></div>}
            </>
          )
        }} /> */}

        <TextField label={useTxtData.table.LogRemark} source="LogRemark" sortable={false} />
        <FunctionField label={'订单时间'} render={(record) => {
          return (
            <>
              创建：<TextField source="CreateTime" /><br />
              {record['PayTime'] && <>支付：<TextField source="PayTime" /></>}
            </>
          )
        }} />
        <FunctionField label={'打印状态'} render={(record) => {
          // let orderNumber = record['OrderNumber'];
          // let orderStatus = record['OrderStatus'];
          let printStatus = record['PrintStatus'];
          return (
            <>
              {printStatus === 1 && <span>待打印</span>}
            </>
          )
        }} />
        {/* <FunctionField label={'操作时间'} render={(record) => {
          return (
            <>
              {record['ReceiptTime'] && <>收货：<TextField source="ReceiptTime" /><br /></>}
              {record['FinishTime'] && <>完成：<TextField source="FinishTime" /><br /></>}
              {record['CancelTime'] && <>取消：<TextField source="CancelTime" /></>}
            </>
          )
        }} /> */}
        <RowAction />
      </Datagrid>
    </List>
  );
};
