import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, MenuItem, ListItemIcon, Collapse, useTheme, Typography, Tooltip } from '@material-ui/core';
import { MenuItemLink, getResources, DashboardMenuItem } from 'react-admin';
import { ExpandMore } from '@material-ui/icons';

import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from '@material-ui/icons/Storefront';
import LanguageIcon from '@material-ui/icons/Language';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SortIcon from '@material-ui/icons/Sort';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import PeopleIcon from '@material-ui/icons/People';
import MessageIcon from '@material-ui/icons/Message';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import AssistantIcon from '@material-ui/icons/Assistant';
import ReorderIcon from '@material-ui/icons/Reorder';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const SubMenu = (props) => {
    const theme = useTheme();
    const [show, setShow] = useState(false);

    return <React.Fragment>
        <MenuItem dense={false} button onClick={() => { setShow(!show) }}>
            {
                !props.open ?
                    <Tooltip title={props.title} placement="right">
                        <ListItemIcon style={{ minWidth: 40 }}>
                            {show ? <ExpandMore /> : props.leftIcon}
                        </ListItemIcon>
                    </Tooltip>
                    :
                    <ListItemIcon style={{ minWidth: 40 }}>
                        {show ? <ExpandMore /> : props.leftIcon}
                    </ListItemIcon>
            }
            <Typography color="textSecondary">
                {props.title}
            </Typography>
        </MenuItem>
        <Collapse in={show} timeout="auto" unmountOnExit style={{ paddingLeft: props.open ? theme.spacing(4) : 0 }}>
            {props.children}
        </Collapse>
    </React.Fragment>
}

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const props = { onMenuClick, logout, open };
    //console.log('resources', resources)

    const groups = [
        { name: '设置', icon: <SettingsIcon /> },
        { name: '成员', icon: <AccountBoxIcon /> },
        { name: '商品', icon: <AssistantIcon /> },
        { name: '订单', icon: <ReorderIcon /> },
    ]

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            {groups.map((group, index) => {
                let resArr = resources.filter(t => t.options.group == group.name);
                if (resArr.length > 0)
                    return (
                        <SubMenu key={index} {...props} title={group.name} leftIcon={group.icon}>
                            {resArr.map(resource => {
                                // console.log('resource', resource)
                                return (
                                    <MenuItemLink
                                        key={resource.name}
                                        to={(resource.options && resource.options.customRoutes) ?
                                            `/${resource.options.customRoutes}` : `/${resource.name}`}
                                        primaryText={resource.options && resource.options.label || resource.name}
                                        leftIcon={resource.icon}
                                        onClick={onMenuClick}
                                        sidebarIsOpen={open}
                                    />
                                )
                            })}
                        </SubMenu>)
                else
                    return null;
            })}

            {/* <MenuItemLink
                to="/custom-route"
                primaryText="Miscellaneous"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            /> */}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;