import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import { Route } from 'react-router-dom';
import qs from 'qs';
import { apiUrl } from './global';
import { httpClient } from './dataProvider';
//import jsonServerProvider from 'ra-data-json-server';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chinese from 'ra-language-chinese';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import MyLayout from './customLayout/layout';
import LoginPage from './loginPage';
import Dashboard from './dashboardPage';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ChangePwd from './customLayout/ChangePwd';

import { AdminList } from './components/Admin';

import { ProductSortList, ProductSortCreate, ProductSortEdit, ProductSortShow } from './components/ProductSort';
import { ProductList, ProductCreate, ProductEdit, ProductShow } from './components/Product';
import { WikiList, WikiCreate, WikiEdit, WikiShow } from './components/Wiki';
import { HelpList, HelpCreate, HelpEdit, HelpShow } from './components/Help';
import { PartnerList, PartnerCreate, PartnerEdit, PartnerShow } from './components/Partner';

import { OrderList, OrderCreate, OrderEdit, OrderShow } from './components/Order';
import { OrderShow as OrderShow2 } from './components/Order';
import { OrderShow as OrderShow3 } from './components/Order';

import { OrderList as OrderList2 } from './components/Order2';
import { OrderList as OrderList3 } from './components/Order3';

import { UserList, UserCreate, UserEdit, UserShow } from './components/User';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SortIcon from '@material-ui/icons/Sort';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import TocIcon from '@material-ui/icons/Toc';
import PrintIcon from '@material-ui/icons/Print';
import PeopleIcon from '@material-ui/icons/People';

//补充缺失的翻译内容 一下是增加一个confirm的中文翻译
chinese.ra.action = { ...chinese.ra.action, confirm: '确认' };
//设置语言为 中文
const i18nProvider = polyglotI18nProvider(() => chinese, 'zh');

const customRoutes = [
  <Route exact path="/ChangePwd" component={ChangePwd} />,
];

const App = () => {

  return (
    <Admin
      title="后台管理"
      layout={MyLayout}
      customRoutes={customRoutes}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      dashboard={Dashboard}
    >
      {permissions => {
        //console.log('permissions', permissions)
        // if (permissions === 'Admin')
        return [
          <Resource options={{ group: '设置', label: '管理员' }} name="Admin/CMSAdmin" list={AdminList} icon={<PeopleAltIcon />} />,
          <Resource options={{ group: '设置', label: 'WiKi', customRoutes: 'Admin/CMSWiki/W001' }} name="Admin/CMSWiki" edit={WikiEdit} icon={<ChromeReaderModeIcon />} />,
          <Resource options={{ group: '设置', label: '常见问题' }} name="Admin/CMSHelp" list={HelpList} create={HelpCreate} edit={HelpEdit} icon={<LiveHelpIcon />} />,
          <Resource options={{ group: '设置', label: '合作伙伴' }} name="Admin/CMSPartner" list={PartnerList} create={PartnerCreate} edit={PartnerEdit} icon={<SupervisedUserCircleIcon />} />,
          <Resource options={{ group: '成员', label: '会员' }} name="Admin/CMSUser" list={UserList} show={UserShow} edit={UserEdit} icon={<PeopleIcon />} />,
          <Resource options={{ group: '商品', label: '分类' }} name="Admin/CMSProductSort" list={ProductSortList} create={ProductSortCreate} edit={ProductSortEdit} show={ProductSortShow} icon={<SortIcon />} />,
          <Resource options={{ group: '商品', label: '商品' }} name="Admin/CMSProduct" list={ProductList} create={ProductCreate} edit={ProductEdit} show={ProductShow} icon={<PlaylistAddCheckIcon />} />,
          <Resource options={{ group: '订单', label: '列表' }} name="Admin/CMSOrder" list={OrderList} show={OrderShow} icon={<TocIcon />} />,
          <Resource options={{ group: '订单', label: '待打印' }} name="Admin/CMSOrder2" list={OrderList2} show={OrderShow2} icon={<PrintIcon />} />,
          <Resource options={{ group: '订单', label: '已打印' }} name="Admin/CMSOrder3" list={OrderList3} show={OrderShow3} icon={<PrintIcon />} />
        ]
      }}
    </Admin>
  )
};

export default App;