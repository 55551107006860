import RichTextInput from 'ra-input-rich-text';

//https://quilljs.com/docs/modules/toolbar/
export const CustomRichTextInput = (props) => {  
    return (
        <RichTextInput {...props} toolbar={[
            //[{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'link', 'image']]} />
    );
}