import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, FunctionField, useRefresh, useNotify } from 'react-admin';
import {
  useRedirect,
  useListContext,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
  TabbedForm,
  FormTab,
  ArrayInput, SimpleFormIterator, FormDataConsumer,

} from 'react-admin';
import { getThumborPic } from '../global'
import { UpLoadFile, FirstImageField } from './custom/UpLoadFile';
import { ArrowBack, Search } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import { Chip, Box, LinearProgress, Snackbar, Alert, Avatar } from '@material-ui/core';
//import ResetOrderNum from './Product_ResetOrderNum';
import { Form } from 'react-final-form';
import { useForm } from 'react-final-form';
import { apiUrl } from '../global'
import qs from 'qs';
import { httpClient } from '../dataProvider'
import { Button as CButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


{/* 
  import { ProductList, ProductCreate, ProductEdit, ProductShow } from './components/Product';

  <Resource name="CMSProduct" list={ProductList} create={ProductCreate} edit={ProductEdit} show={ProductShow} />
  <Resource name="CMSProduct" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: 'Product',
  },
  table: {
    id: 'id',
    Code: '货号',
    Title: '标题',
    SubTitle: '副标题',
    KeyWords: '关键词',
    ProductPics: '图片',
    Open: '上架',
    IsHot: '热门',
    IsNew: '新品',
    IsLowPrice: '低价',
    MinPrice: '最低价',
    MaxPrice: '最高价',
    SaleCount: '销量',
    OrderNum: '排序',
    CreateTime: '创建时间',
    UpdateTime: '更新时间',
  }
};

//分页列表页面
export const ProductList = (props) => {
  //筛选器模块
  // const Filters = (props) => {
  //   return (
  //     <Filter {...props}>
  //       <TextInput label="ID" source="id" alwaysOn resettable />
  //       {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
  //     </Filter>
  //   );
  // };
  const Filters = (props) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = values => { if (Object.keys(values).length > 0) { setFilters(values); } else { setFilters({}, []); } };
    const resetFilter = () => { setFilters({}, []); };
    return (
      <div>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display='flex' alignItems='center'>
                <Box component="span" mr={2}>
                  <TextInput label="货号/标题/副标题/关键词" source="key" resettable />
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="contained" color="primary" type="submit" startIcon={<Search />}>搜索</CButton>
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="outlined" onClick={resetFilter}>重置</CButton>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </div>
    );
  };

  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        {/* <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        {/* <ShowButton {...props} /><br /> */}
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const ProductPics = (props) => {
    let ProductPics = props.record['ProductPics'];
    // let ProductPicsCopy = [...ProductPics];
    let [productPicsCopy, setProductPicsCopy] = useState([...ProductPics]);
    // console.log(props.record['ProductPics']);
    const refresh = useRefresh();
    const notify = useNotify();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const handleSubmit = () => {
      httpClient(`${apiUrl}/Admin/CMSProductSort/EditProductPics`, {
        method: 'POST',
        body: JSON.stringify({ productId: props.record['id'], ProductPics: productPicsCopy })
      }).then(({ json }) => {
        if (json.Code == 1)
          refresh();
        else
          notify(json.Message);
      })
    }

    const swapArray = (arr, index1, index2) => {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    }
    const backClick = (index) => {
      if (index === 0) return;
      let newV = swapArray(productPicsCopy, index, index - 1);
      setProductPicsCopy([...newV]);
    }
    const forwardClick = (index) => {
      if (index === productPicsCopy.length - 1) return;
      let newV = swapArray(productPicsCopy, index, index + 1);
      setProductPicsCopy([...newV]);
    }

    return (
      <>
        <div style={{ position: 'relative' }} onClick={() => handleClickOpen()}>
          <FirstImageField {...props} picResize source="ProductPics" className={classes.imageField} />
          <div style={{ position: 'absolute', top: 3, left: 3, color: '#FFF', backgroundColor: '#008000CC', borderRadius: 999, padding: '0 6px' }}>{ProductPics.length}</div>
        </div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            查看/调整图片顺序
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 500 }}>
              {productPicsCopy.map((pic, index) => {
                let _pic = getThumborPic(pic, 60, 60);
                return (
                  <div style={{ padding: 10 }}>
                    <img src={_pic} />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <ArrowBackIcon onClick={() => backClick(index)} style={{ opacity: index === 0 ? 0.2 : 1 }} />
                      <ArrowForwardIcon onClick={() => forwardClick(index)} style={{ opacity: index === productPicsCopy.length - 1 ? 0.2 : 1 }} />
                    </div>
                  </div>
                )
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <CButton onClick={handleSubmit} color="primary">
              保存
            </CButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <ProductPics label={useTxtData.table.ProductPics} />

        <FunctionField label={'货号/标题'} render={(record) => {
          return (
            <>
              <div>{record['Code']}</div>
              <div>{record['Title']}</div>
            </>
          )
        }} />

        {/* <TextField label={useTxtData.table.SubTitle} source="SubTitle" />
        <TextField label={useTxtData.table.KeyWords} source="KeyWords" /> */}
        <FunctionField label={'副标题/关键词'} render={(record) => {
          return (
            <>
              {(record['SubTitle'] ?? "").length > 0 && <div>{record['SubTitle']}</div>}
              {(record['KeyWords'] ?? "").length > 0 && <div><Chip size="small" label={record['KeyWords']} /></div>}
            </>
          )
        }} />
        <BooleanField label={useTxtData.table.Open} source="Open" />
        <BooleanField label={useTxtData.table.IsHot} source="IsHot" />
        <BooleanField label={useTxtData.table.IsNew} source="IsNew" />
        <BooleanField label={useTxtData.table.IsLowPrice} source="IsLowPrice" />
        {/* <TextField label={useTxtData.table.MinPrice} source="MinPrice" />
        <TextField label={useTxtData.table.MaxPrice} source="MaxPrice" /> */}
        <FunctionField label={'价格'} render={(record) => {
          return (
            <span>¥{record['MinPrice']}~{record['MaxPrice']}</span>
          )
        }} />
        <TextField label={useTxtData.table.SaleCount} source="SaleCount" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const ProductShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.Title} source="Title" />
        <TextField label={useTxtData.table.SubTitle} source="SubTitle" />
        <TextField label={useTxtData.table.KeyWords} source="KeyWords" />
        <TextField label={useTxtData.table.ProductPics} source="ProductPics" />
        <TextField label={useTxtData.table.IsHot} source="IsHot" />
        <TextField label={useTxtData.table.IsNew} source="IsNew" />
        <TextField label={useTxtData.table.IsLowPrice} source="IsLowPrice" />
        <TextField label={useTxtData.table.MinPrice} source="MinPrice" />
        <TextField label={useTxtData.table.MaxPrice} source="MaxPrice" />
        <TextField label={useTxtData.table.SaleCount} source="SaleCount" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const ProductEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const ProductCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <TabbedForm {...props} >
      <FormTab label="基础数据">
        {/* <TextInput source="Name" /> */}
        {/* <NumberInput source="OrderNum" /> */}
        {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
        <UpLoadFile multiple label={useTxtData.table.ProductPics} source="ProductPics" validate={[required()]} />
        <TextInput label={useTxtData.table.Code} source="Code" validate={[required()]} />
        <TextInput label={useTxtData.table.Title} source="Title" validate={[required()]} />
        <TextInput label={useTxtData.table.SubTitle} source="SubTitle" validate={[]} />
        <TextInput label={useTxtData.table.KeyWords} source="KeyWords" validate={[]} />
        <BooleanInput label={useTxtData.table.Open} source="Open" validate={[required()]} defaultValue={false} />
        <BooleanInput label={useTxtData.table.IsHot} source="IsHot" validate={[required()]} defaultValue={false} />
        <BooleanInput label={useTxtData.table.IsNew} source="IsNew" validate={[required()]} defaultValue={false} />
        <BooleanInput label={useTxtData.table.IsLowPrice} source="IsLowPrice" validate={[required()]} defaultValue={false} />
        {/* <TextInput label={useTxtData.table.MinPrice} source="MinPrice" validate={[required()]} />
      <TextInput label={useTxtData.table.MaxPrice} source="MaxPrice" validate={[required()]} /> */}
        {/* <TextInput label={useTxtData.table.SaleCount} source="SaleCount" validate={[required()]} /> */}
        <NumberInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} defaultValue={0} />
        {/* <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} />
      <TextInput label={useTxtData.table.UpdateTime} source="UpdateTime" validate={[required()]} /> */}
      </FormTab>
      <FormTab label="所属分类">
        <MyReferenceArrayInput />
      </FormTab>
      <FormTab label="规格">
        <ArrayInput source="GG" label=''>
          <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
            <FormDataConsumer>
              {(props) => {
                var { getSource, formData } = props;
                return <UseFormComponent>{
                  (form) => (
                    <Box key={getSource("id")} display="flex" alignItems='center' style={{ width: '100%' }}>
                      {/* <Box mr={2}>
                        <BooleanInput source={getSource("Open")} label="开启" validate={[required()]} defaultValue={true} />
                      </Box> */}
                      {/* <Box mr={2} style={{ width: 260 }}>
                        <ReferenceInput fullWidth label="供应商" source={getSource("RecruitProductSupplierId")} reference="Admin/CMSRecruitProductSupplier" validate={[required()]}>
                          <SelectInput optionText="SupplierName" />
                        </ReferenceInput>
                      </Box> */}
                      <Box mr={2}>
                        <TextInput source={getSource("GGCode")} label="规格货号" validate={[required()]} />
                      </Box>
                      <Box mr={2}>
                        <TextInput source={getSource("GGTitle")} label="规格名称" validate={[required()]} />
                      </Box>
                      <Box mr={2}>
                        <NumberInput source={getSource("GGPrice")} label="售价" validate={[required()]} defaultValue={0} />
                      </Box>
                      <Box mr={2}>
                        <NumberInput source={getSource("GGManyBeOneUitPrice")} label="每斤均价" />
                      </Box>
                      <Box mr={2}>
                        <NumberInput source={getSource("Count")} label="库存" validate={[required()]} defaultValue={0} step={1} />
                      </Box>
                      <Box mr={2}>
                        <NumberInput source={getSource("SaleCount")} label="销售" validate={[required()]} defaultValue={0} step={1} />
                      </Box>
                    </Box>
                  )}</UseFormComponent>
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="商品参数">
        <ArrayInput source="KeyDescs" label=''>
          <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
            <FormDataConsumer>
              {(props) => {
                var { getSource, formData } = props;
                return <UseFormComponent>{
                  (form) => (
                    <Box key={getSource("id")} display="flex" alignItems='center' style={{ width: '100%' }}>
                      {/* <Box mr={2}>
                        <BooleanInput source={getSource("Open")} label="开启" validate={[required()]} defaultValue={true} />
                      </Box> */}
                      {/* <Box mr={2} style={{ width: 260 }}>
                        <ReferenceInput fullWidth label="供应商" source={getSource("RecruitProductSupplierId")} reference="Admin/CMSRecruitProductSupplier" validate={[required()]}>
                          <SelectInput optionText="SupplierName" />
                        </ReferenceInput>
                      </Box> */}
                      <Box mr={2}>
                        <TextInput source={getSource("Title")} label="名称" validate={[required()]} />
                      </Box>
                      <Box mr={2}>
                        <TextInput source={getSource("Value")} label="内容" validate={[required()]} />
                      </Box>
                    </Box>
                  )}</UseFormComponent>
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
}

const UseFormComponent = ({ children }) => {
  //两个ReferenceInput联动的问题，默认次级选中的值不会被清空
  //https://github.com/marmelab/react-admin/issues/2431 官方的问题
  //https://github.com/marmelab/react-admin/issues/3676#issuecomment-595240330 解决问题的方法
  const form = useForm();
  if (typeof children === 'function')
    return children && children(form);
};

const MyReferenceArrayInput = (props) => {
  const { SortIDs = [] } = props.record;
  // console.log('SortIDs', SortIDs)

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    httpClient(`${apiUrl}/Admin/CMSProductSort/GetList?_order=DESC&_page=0&_pagesize=999&_sort=OrderNum`, {
      method: 'GET',
      //contentType: 'application/x-www-form-urlencoded',
      //body: qs.stringify({ EnumName: props.EnumName })
    }).then(({ json }) => {
      console.log('json', json)
      setLoading(false);
      if (json.Code == 1)
        setData(json.Result);
      else
        setError(json.Message);
    })
  }, []);

  const handleClose = () => {
    setError(null);
  }

  if (SortIDs == null) return null;
  if (loading) return <LinearProgress />;
  if (error)
    return (
      <div>{error}</div>
    )
  if (!data) return null

  return (
    <FormDataConsumer>
      {(props) => {
        var { formData } = props;
        return <UseFormComponent>{
          (form) => {
            const chipClick = (itemId, itemId2) => {
              let oldIds = formData.SortIDs === undefined ? [] : formData.SortIDs;
              console.log('oldIds', oldIds)

              var newIds = [];
              var exist = false;
              oldIds.forEach((item, index) => {
                console.log('item', item)
                if (item[0] === itemId && item[1] === itemId2) {
                  exist = true;
                }
                else {
                  newIds.push(item);
                }
              })
              if (!exist) newIds.push([itemId, itemId2]);
              console.log('newIds', newIds)

              form.change('SortIDs', []);
              form.change('SortIDs', newIds);
            }

            return data.data.filter(t => t.PProductSortId === 0).map((item, index) => {
              let Ji = item.PProductSortId;
              if (Ji === 0) {
                let subItems = data.data.filter(t => t.PProductSortId === item.id);//二级
                subItems = [{ id: 0, ParentRecruitSortId: item.id, SortName: "默认", }, ...subItems];
                return (
                  <Box>
                    <Box m={1} display='flex'>
                      <Box>{item.SortName}</Box>
                    </Box>
                    <Box m={2} display='flex' flexWrap="wrap">
                      {
                        subItems.map((item2, index2) => {
                          let isDone = false;
                          if (formData.SortIDs !== undefined) {
                            formData.SortIDs.forEach((s, i) => {
                              if (s[0] === item.id && s[1] === item2.id
                                && !isDone) {
                                isDone = true;
                              }
                            });
                          }
                          return (
                            <Box m={1}><Chip color={isDone ? "secondary" : ''} onClick={() => chipClick(item.id, item2.id)} avatar={isDone && <Avatar><DoneIcon /></Avatar>} label={item2.SortName} />
                            </Box>
                          );
                        })
                      }
                    </Box>
                  </Box>
                );
              }
              else
                return null;
            })
          }
        }</UseFormComponent>
      }}
    </FormDataConsumer>)
}