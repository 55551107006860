import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, FunctionField, useMutation, useQueryWithStore } from 'react-admin';
import { useNotify, useRefresh, useAuthProvider } from 'react-admin';
import {
  useRedirect, useListContext,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import { UpLoadFile } from './custom/UpLoadFile';
import { ArrowBack, Search } from '@material-ui/icons';
import { apiUrl, getThumborPic } from '../global';
import { httpClient } from '../dataProvider';
//import ResetOrderNum from './Order_ResetOrderNum';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button as CButton, TextField as CTextField, Chip, IconButton, Box } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from 'react-final-form';
import qs from 'qs';

{/* 
  import { OrderList, OrderCreate, OrderEdit, OrderShow } from './components/Order';

  <Resource name="CMSOrder" list={OrderList} create={OrderCreate} edit={OrderEdit} show={OrderShow} />
  <Resource name="CMSOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: 'Order',
  },
  table: {
    id: 'id',
  }
};

//分页列表页面
export const OrderList = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  //筛选器模块
  // const Filters = (props) => {
  //   return (
  //     <Filter {...props}>
  //       {/* <TextInput label="ID" source="id" alwaysOn resettable /> */}
  //       {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
  //     </Filter>
  //   );
  // };
  const Filters = (props) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = values => { if (Object.keys(values).length > 0) { setFilters(values); } else { setFilters({}, []); } };
    const resetFilter = () => { setFilters({}, []); };
    return (
      <div>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display='flex' alignItems='center'>
                <Box component="span" mr={2}>
                  <TextInput label="订单号/流水号" source="key" resettable />
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="contained" color="primary" type="submit" startIcon={<Search />}>搜索</CButton>
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="outlined" onClick={resetFilter}>重置</CButton>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </div>
    );
  };

  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        {/* <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = ({ record: { PrintNumber } }) => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton {...props} /><br /> */}
        {/* <ShowButton {...props} /><br /> */}
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
        {/* <CancelWaitPrint {...props} /> */}
        <CButton color='primary' onClick={() => DownloadFHD(PrintNumber)}>下载发货单</CButton>
      </div>
    );
  }

  const DownloadFHD = (PrintNumber) => {
    if (window.confirm('确定要重新下载发货单吗?')) {
      httpClient(apiUrl + `/Admin/CMSOrder3/ReDownLoadTask`, {
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: qs.stringify({ PrintNumber }),
      }).then(({ headers, json }) => {
        if (json.Code == 1) {
          notify('发货单创建成功，请自行打印');
          refresh();
          DownPrintPdf(json.Result);
        }
        else
          notify(json.Message);
      }).finally(() => {

      })
    }
  }

  const DownPrintPdf = ({ url, fileName, printNumber }) => {
    var anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = `${apiUrl}${url}`;
    anchor.target = "_blank";
    anchor.download = `${fileName}`;
    anchor.click();
  }

  const Order = ({ record }) => {
    let { PrintNumber } = record;
    const notify = useNotify();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      httpClient(`${apiUrl}/Admin/CMSOrder3/GetOrderList`, {
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: qs.stringify({ PrintNumber })
      }).then(({ json }) => {
        setLoading(false);
        if (json.Code == 1)
          setData(json.Result.data);
        else {
          notify(json.Message);
        }
      })
    }, []);

    const DownloadFHD2 = (OrderNumber) => {
      if (window.confirm('确定要重新下载发货单吗?')) {
        httpClient(apiUrl + `/Admin/CMSOrder3/ReDownLoadTask2`, {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: qs.stringify({ OrderNumber }),
        }).then(({ headers, json }) => {
          if (json.Code == 1) {
            notify('发货单创建成功，请自行打印');
            refresh();
            DownPrintPdf(json.Result);
          }
          else
            notify(json.Message);
        }).finally(() => {

        })
      }
    }

    if (loading) return <CircularProgress />;
    if (!data) return null;

    return (
      <Table size="small" style={{ width: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>编号</TableCell>
            <TableCell>订单号</TableCell>
            <TableCell>订单状态</TableCell>
            <TableCell>会员</TableCell>
            <TableCell>收获人</TableCell>
            <TableCell>联系电话</TableCell>
            <TableCell>总件数</TableCell>
            <TableCell>创建时间</TableCell>
            <TableCell>支付时间</TableCell>
            <TableCell>货到付款</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, OrderNumber, OrderStatus, UserNickName, UserAccount, Name, Mobile, TotalCount, CreateTime, Unlinepay, PayTime }, index) => (
            <TableRow key={id}>
              <TableCell>
                {index + 1}
              </TableCell>
              <TableCell>{OrderNumber}</TableCell>
              <TableCell>{OrderStatus}</TableCell>
              <TableCell>{`${UserAccount}`}</TableCell>
              <TableCell>{Name}</TableCell>
              <TableCell>{Mobile}</TableCell>
              <TableCell>{TotalCount}</TableCell>
              <TableCell>{CreateTime}</TableCell>
              <TableCell>{PayTime}</TableCell>
              <TableCell>{Unlinepay ? '是' : ''}</TableCell>
              <TableCell>
                <CButton color='primary' onClick={() => DownloadFHD2(OrderNumber)}>下载发货单</CButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'PrintTime', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} exporter={false} empty={false} >
      <Datagrid expand={<Order />}>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={'打印流水号'} source="PrintNumber" sortable={false} />
        <TextField label={'打印订单数'} source="Count" sortable={false} />
        <TextField label={'打印时间'} source="PrintTime" sortable={false} />
        <RowAction />
      </Datagrid>
    </List>
  );
};