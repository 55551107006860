import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Box, Typography, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect, useState } from "react";
import { httpClient } from './dataProvider';
import { apiUrl } from './global';
// import { useQuery, Loading, Error } from 'react-admin';
import { useQueryWithStore, Loading, Error } from 'react-admin';
import { useNotify } from "react-admin";
/* import { webUrl } from './global.js' */

const useStyles = makeStyles({
    divider: {
        margin: '6px 0 10px 0',
    },
});

const Dashboard = () => {
    const classes = useStyles();

    const notify = useNotify();
    // const { data, loading, error } = useQuery({
    const { data, loading, error } = useQueryWithStore({
        type: 'customGetFecth',
        resource: 'Admin/Base',
        payload: {
            name: 'GetDashboardData',
            params: {},
            success: (result) => {
                return result.Result;
            }
        }
    });
    if (loading) return <CircularProgress size={20} />;
    if (error) return <Error />;
    if (!data) return null;

    console.log('data', data)
    const { allProductCount, allUserCount, now7OrderCount, now7UserCount, nowJDOrderCount, nowMonthOrderCount, nowMonthUserCount, nowOpenProductCount, nowOrderCount, nowUserCount, unFinishOrderCount, allOrderCount, unPayOrderCount, unChuKuOrderCount, unPeiSongOrderCount, unShouHuoOrderCount
    } = data;
    return (
        <Card>
            <CardHeader title="控制台" />
            <CardContent>
                <Box display={'flex'} >
                    <Box m={1} flex={1}>
                        <Card>
                            <CardContent>
                                <Typography variant="overline" gutterBottom>
                                    订单概况
                                </Typography>
                                <Divider className={classes.divider} />
                                <Box display={'flex'} justifyContent={'start'} >
                                    <Box flex={1}>
                                        <Typography variant="body2" gutterBottom>
                                            今日订单: <Typography component="span" variant="body2" gutterBottom>{nowOrderCount}</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            7日订单: <Typography component="span" variant="body2" gutterBottom>{now7OrderCount}</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            本月订单: <Typography component="span" variant="body2" gutterBottom>{nowMonthOrderCount}</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            季度订单: <Typography component="span" variant="body2" gutterBottom>{nowJDOrderCount}</Typography>
                                        </Typography>
                                    </Box>
                                    <Box flex={1}>
                                        <Typography variant="body2" gutterBottom>
                                            待付款: <Typography component="span" variant="body2" gutterBottom>{unPayOrderCount}</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            待配送: <Typography component="span" variant="body2" gutterBottom>{unPeiSongOrderCount}</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            已完成: <Typography component="span" variant="body2" gutterBottom>{unFinishOrderCount}</Typography>
                                        </Typography>
                                    </Box>
                                    <Box flex={1}>
                                        <Typography variant="body2" gutterBottom>
                                            待出库: <Typography component="span" variant="body2" gutterBottom>{unChuKuOrderCount}</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            待收货: <Typography component="span" variant="body2" gutterBottom>{unShouHuoOrderCount}</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            总订单: <Typography component="span" variant="body2" gutterBottom>{allOrderCount}</Typography>
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box m={1} flex={1}>
                        <Card>
                            <CardContent>
                                <Typography variant="overline" gutterBottom>
                                    会员统计
                                </Typography>
                                <Divider className={classes.divider} />
                                <Typography variant="body2" gutterBottom>
                                    今日注册: <Typography component="span" variant="body2" gutterBottom>{nowUserCount}</Typography>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    7日注册: <Typography component="span" variant="body2" gutterBottom>{now7UserCount}</Typography>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    本月注册: <Typography component="span" variant="body2" gutterBottom>{nowMonthUserCount}</Typography>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    共计会员: <Typography component="span" variant="body2" gutterBottom>{allUserCount}</Typography>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box m={1} flex={1}>
                        <Card>
                            <CardContent>
                                <Typography variant="overline" gutterBottom>
                                    商品统计
                                </Typography>
                                <Divider className={classes.divider} />
                                <Typography variant="body2" gutterBottom>
                                    上架商品: <Typography component="span" variant="body2" gutterBottom>{nowOpenProductCount}</Typography>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    总计商品: <Typography component="span" variant="body2" gutterBottom>{allProductCount}</Typography>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    {/* <Box m={1} flex={1}>
                    <Card>
                        <CardContent>内容</CardContent>
                    </Card>
                </Box> */}
                </Box>
            </CardContent>
        </Card>
    )
};


export default Dashboard;