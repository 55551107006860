import * as React from 'react';
import { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, FunctionField, useMutation, useQueryWithStore } from 'react-admin';
import { useNotify, useRefresh, useAuthProvider } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
  TabbedShowLayout,
  Tab,
  useListContext
} from 'react-admin';
import { UpLoadFile } from './custom/UpLoadFile';
import { ArrowBack, Search } from '@material-ui/icons';
import { apiUrl, getThumborPic } from '../global';
import { httpClient } from '../dataProvider';
//import ResetOrderNum from './Order_ResetOrderNum';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button as CButton, TextField as CTextField, Chip, IconButton, Box } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import qs from 'qs';
import { Form } from 'react-final-form';
import { SelectByEnum } from './custom/Enum';
{/* 
  import { OrderList, OrderCreate, OrderEdit, OrderShow } from './components/Order';

  <Resource name="CMSOrder" list={OrderList} create={OrderCreate} edit={OrderEdit} show={OrderShow} />
  <Resource name="CMSOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: 'Order',
  },
  table: {
    id: 'id',
    OrderNumber: '订单号',
    OrderStatus: '订单状态',
    UserId: '会员',
    Name: '收货人',
    Mobile: '手机号',
    Province: '省',
    City: '市',
    Area: '区',
    Addr: '地址',
    LogRemark: '会员备注',
    TotalCount: '总件数',
    TotalPrice: '总金额',
    PayStyle: '付款方式',
    CreateTime: '创建时间',
    PayTime: '支付时间',
    ReceiptTime: '收货时间',
    FinishTime: '完成时间',
    CancelTime: '取消时间',
    Discount: '优惠金额',
    Discount: '优惠金额',
    Discount: '优惠金额',
    UserAccount: '会员账号',
    UserNickName: '会员昵称',
    PrintStatus: '打印状态',
    PrintTime: '打印时间',
  }
};

//分页列表页面
export const OrderList = (props) => {
  // //筛选器模块
  // const Filters = (props) => {
  //   return (
  //     <Filter {...props}>
  //       <TextInput label="订单号/手机号/收货人" source="key" alwaysOn resettable />
  //       {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
  //     </Filter>
  //   );
  // };
  //筛选器模块（自定义筛选模块，可以仿制一个搜索按钮）
  const Filters = (props) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = values => { if (Object.keys(values).length > 0) { setFilters(values); } else { setFilters({}, []); } };
    const resetFilter = () => { setFilters({}, []); };
    return (
      <div>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display='flex' alignItems='center'>
                <Box component="span" mr={2}>
                  {/* <TextInput label="订单号/手机号/收货人" source="key" resettable /> */}
                  <SelectByEnum label="订单状态" source="status" EnumName='api.Models.OrderStatus' OnlyText={true} resettable />
                </Box>
                <Box component="span" mr={2}>
                  <TextInput label="订单号/手机号/收货人" source="key" resettable />
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="contained" color="primary" type="submit" startIcon={<Search />}>搜索</CButton>
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="outlined" onClick={resetFilter}>重置</CButton>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </div>
    );
  };


  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        {/* <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton {...props} /><br /> */}
        <ShowButton {...props} /><br />
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const OrderStatus = ({ record: { OrderStatus } }) => {
    // console.log('OrderStatus', OrderStatus)
    let color = '#FFF';
    let backgroundColor = '#ffb100de';
    switch (OrderStatus) {
      case '待付款': break;
      case '待出库': backgroundColor = '#0080008a'; break;
      case '待配送': backgroundColor = '#0000ff8a'; break;
      case '待收货': color = ''; backgroundColor = '#ff00008a'; break;
      case '已完成': color = ''; backgroundColor = '#5a5a5aab'; break;
      case '已取消': color = ''; backgroundColor = '#5a5a5a47'; break;
    }
    return (
      <Chip size="small" label={OrderStatus} style={{ color, backgroundColor }} />
    )
  }

  const AddWaitPrint = ({ resource, record: { id, OrderNumber } }) => {
    const [mutate, { loading }] = useMutation();
    const refresh = useRefresh();
    const notify = useNotify();
    const [doing, setDoing] = React.useState(false);
    const [result, setResult] = React.useState('');
    React.useEffect(() => {

    }, [])

    const clickHandle = () => {
      setDoing(true);
      httpClient(apiUrl + `/Admin/CMSOrder/AddWaitPrint`, {
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: qs.stringify({ orderNumber: OrderNumber }),
      }).then(({ headers, json }) => {
        if (json.Code == 1) {
          // mutate({
          //   type: 'update', resource,
          //   payload: { id, data: { PrintStatus: 1, updatedAt: new Date() } },
          // });
          notify('加入打印队列成功');
          refresh();
        }
        else
          notify(json.Message);
      }).finally(() => {
        setDoing(false);
      })
    }

    if (loading) return <CircularProgress />;

    return (
      <IconButton color="primary" component="span" onClick={clickHandle}>
        <AddIcon />
      </IconButton>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} actions={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        <FunctionField label={'订单/状态'} render={(record) => {
          return (
            <>
              <TextField label={useTxtData.table.OrderNumber} source="OrderNumber" /><br />
              {/* <TextField label={useTxtData.table.OrderStatus} source="OrderStatus" /> */}
              <OrderStatus record={record} />
            </>
          )
        }} />
        <FunctionField label={'会员'} render={() => {
          return (
            <>
              <TextField source="UserAccount" /><br />
              <TextField source="UserNickName" />
            </>
          )
        }} />
        <FunctionField label={'收货地址'} render={() => {
          return (
            <>
              <TextField label={useTxtData.table.Name} source="Name" />
              <TextField label={useTxtData.table.Mobile} source="Mobile" />
              <br />
              <TextField label={useTxtData.table.City} source="City" />
              <TextField label={useTxtData.table.Area} source="Area" />
              <TextField label={useTxtData.table.Addr} source="Addr" />
            </>
          )
        }} />
        <TextField label={'总件数'} source="TotalCount" sortable={false} />
        <FunctionField label={'订单金额'} render={(record) => {
          return (
            <>
              <div style={{ fontSize: 10, color: 'red' }}>{record['Unlinepay'] === true ? '货到付款' : ''}</div>
              ¥<TextField source="TotalPrice" />
              <br />{(parseFloat(record['Discount']) != 0) && <div style={{ fontSize: 10, color: 'red' }}>惠：¥<TextField source="Discount" /></div>}

            </>
          )
        }} />

        <TextField label={useTxtData.table.LogRemark} source="LogRemark" sortable={false} />
        <FunctionField label={'订单时间'} render={(record) => {
          return (
            <>
              创建：<TextField source="CreateTime" /><br />
              {record['PayTime'] && <>支付：<TextField source="PayTime" /></>}
            </>
          )
        }} />
        <FunctionField label={'打印状态'} render={(record) => {
          let orderNumber = record['OrderNumber'];
          let orderStatus = record['OrderStatus'];
          let printStatus = record['PrintStatus'];
          return (
            <>
              {printStatus === 2 ? <span>已打印</span> : (printStatus === 1 ? <span>待打印</span> : <>
                {orderStatus === '待出库' &&
                  <AddWaitPrint record={record} {...props} />
                }
              </>)}<br />
              {record['PrintTime'] && <TextField source="PrintTime" />}
            </>
          )
        }} />
        <FunctionField label={'操作时间'} render={(record) => {
          return (
            <>
              {record['ReceiptTime'] && <>收货：<TextField source="ReceiptTime" /><br /></>}
              {record['FinishTime'] && <>完成：<TextField source="FinishTime" /><br /></>}
              {record['CancelTime'] && <>取消：<TextField source="CancelTime" /></>}
            </>
          )
        }} />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const OrderShow = (props) => {

  const ShowActions = (props) => {
    const redirect = useRedirect();
    if (!props.data) return null;
    const { basePath, data: { OrderStatus, OrderNumber, Unlinepay } } = props;
    return (
      <TopToolbar>
        {OrderStatus === '待付款' &&
          <>
            <SetDiscount orderNumber={OrderNumber} />
            <CancelOrder orderNumber={OrderNumber} />
          </>
        }
        {(OrderStatus === '待出库' && Unlinepay === true) &&
          <>
            <SetDiscount orderNumber={OrderNumber} />
            <CancelOrder orderNumber={OrderNumber} />
          </>
        }
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };

  const CancelOrder = ({ orderNumber }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const handleClickOpen = () => {
      if (window.confirm('确定要取消订单吗？')) {
        httpClient(apiUrl + `/Admin/CMSOrder/CancelOrder`, {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: qs.stringify({ orderNumber }),
        }).then(({ headers, json }) => {
          if (json.Code == 1) {
            refresh();
          }
          else
            notify(json.Message);
        });
      }
    };
    return (
      <Box mr={2}>
        <CButton variant="contained" style={{ backgroundColor: 'red', color: '#FFF' }} onClick={handleClickOpen}>取消订单</CButton>
      </Box>
    )
  }
  const SetDiscount = ({ orderNumber }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const [val, setVal] = React.useState(0);
    useEffect(() => {

    }, [])

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
    const submit = () => {
      console.log('val', val);
      let value = parseFloat(val);
      if (isNaN(value)) value = 0;
      if (value == 0) alert('金额不能为0')
      else {
        httpClient(apiUrl + `/Admin/CMSOrder/SetDiscount`, {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: qs.stringify({ orderNumber, value }),
        }).then(({ headers, json }) => {
          if (json.Code == 1) {
            notify('设置成功'); handleClose(); refresh();
          }
          else
            notify(json.Message);
        });
      }
    };

    return (
      <Box mr={2}>
        <CButton variant="contained" color="primary" onClick={handleClickOpen}>设置优惠</CButton>
        <Dialog open={open}>
          <DialogTitle>设置优惠</DialogTitle>
          <DialogContent style={{ minWidth: 300 }}>
            <DialogContentText>
              例如：优惠100元，请输入 -100
            </DialogContentText>
            <CTextField autoFocus margin="dense" label="优惠金额" type="number" fullWidth value={val} onChange={(e) => setVal(e.target.value)} />
          </DialogContent>
          <DialogActions>
            <CButton onClick={handleClose} color="primary">
              取消
            </CButton>
            <CButton onClick={submit} color="primary">
              确定
            </CButton>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }

  const OrderProductList = ({ record: { OrderNumber } }) => {
    // console.log('props', OrderNumber);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    useEffect(() => {
      httpClient(apiUrl + `/Admin/CMSOrder/GetOrderProducts?orderNumber=${OrderNumber}`, {
        method: 'GET'
      }).then(({ headers, json }) => {
        // console.log(json)
        if (json.Code == 1) {
          setData(json.Result);
          setLoading(false);
        }
      });
    }, [])

    if (loading) return <CircularProgress />

    return (
      <Table size="small" style={{ width: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell >序号</TableCell>
            <TableCell >图片</TableCell>
            <TableCell >产品</TableCell>
            <TableCell >规格名称</TableCell>
            <TableCell >规格货号</TableCell>
            <TableCell >订购单价</TableCell>
            <TableCell >订购数量</TableCell>
            <TableCell >订购总价</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, ppic, ptitle, ggTitle, ggCode, ggPrice, ggCount }, index) => {
            let _ppic = getThumborPic(ppic, 30, 30);
            return (
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell><img src={_ppic} style={{ width: 30, height: 30 }} /></TableCell>
                <TableCell>{ptitle}</TableCell>
                <TableCell>{ggTitle}</TableCell>
                <TableCell>{ggCode}</TableCell>
                <TableCell>¥{ggPrice}</TableCell>
                <TableCell>{ggCount}</TableCell>
                <TableCell>¥{ggPrice * ggCount}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  const OrderPayList = ({ record: { OrderNumber } }) => {
    // console.log('props', OrderNumber);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    useEffect(() => {
      httpClient(apiUrl + `/Admin/CMSOrder/GetOrderPayList?orderNumber=${OrderNumber}`, {
        method: 'GET'
      }).then(({ headers, json }) => {
        // console.log(json)
        if (json.Code == 1) {
          setData(json.Result);
          setLoading(false);
        }
      });
    }, [])

    if (loading) return <CircularProgress />

    return (
      <Table size="small" style={{ width: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell >序号</TableCell>
            <TableCell >支付单号</TableCell>
            <TableCell >金额</TableCell>
            <TableCell >状态</TableCell>
            <TableCell >付款时间</TableCell>
            <TableCell >微信单号</TableCell>
            <TableCell >退款金额</TableCell>
            <TableCell >实际收款</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, payNumber, amountOfMoney, isPay, payTime, weixinPayNumber, totalRefundAmount, actualAmount }, index) => {
            return (
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{payNumber}</TableCell>
                <TableCell>¥{amountOfMoney}</TableCell>
                <TableCell>{isPay ? '已支付' : '未支付'}</TableCell>
                <TableCell>{payTime}</TableCell>
                <TableCell>{weixinPayNumber}</TableCell>
                <TableCell>¥{totalRefundAmount}</TableCell>
                <TableCell>¥{actualAmount}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  const OrderLogs = ({ record: { OrderNumber } }) => {
    // console.log('props', OrderNumber);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    useEffect(() => {
      httpClient(apiUrl + `/Admin/CMSOrder/GetOrderLogs?orderNumber=${OrderNumber}`, {
        method: 'GET'
      }).then(({ headers, json }) => {
        // console.log(json)
        if (json.Code == 1) {
          setData(json.Result);
          setLoading(false);
        }
      });
    }, [])

    if (loading) return <CircularProgress />

    return (
      <Table size="small" style={{ width: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell >序号</TableCell>
            <TableCell >操作人类型</TableCell>
            <TableCell >操作人</TableCell>
            <TableCell >日志标题</TableCell>
            <TableCell >日志内容</TableCell>
            <TableCell >记录时间</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, czrType, czrName, logTitle, logCont, upTime }, index) => {
            return (
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{czrType}</TableCell>
                <TableCell>{czrName}</TableCell>
                <TableCell>{logTitle}</TableCell>
                <TableCell>{logCont}</TableCell>
                <TableCell>{upTime}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <TabbedShowLayout>
        <Tab label="订单概况">
          {/* <TextField source="id" /> */}
          {/* <TextField label="名称" source="Name" /> */}
          {/* <TextField label="排序" source="OrderNum" />  */}
          {/* <TextField label={useTxtData.table.id} source="id" /> */}
          <TextField label={useTxtData.table.OrderNumber} source="OrderNumber" />
          <TextField label={useTxtData.table.OrderStatus} source="OrderStatus" />
          {/* <TextField label={useTxtData.table.UserId} source="UserId" /> */}
          <TextField label={useTxtData.table.UserAccount} source="UserAccount" />
          <TextField label={useTxtData.table.UserNickName} source="UserNickName" />
          <TextField label={useTxtData.table.Name} source="Name" />
          <TextField label={useTxtData.table.Mobile} source="Mobile" />
          <TextField label={useTxtData.table.Province} source="Province" />
          <TextField label={useTxtData.table.City} source="City" />
          <TextField label={useTxtData.table.Area} source="Area" />
          <TextField label={useTxtData.table.Addr} source="Addr" />
          <TextField label={useTxtData.table.LogRemark} source="LogRemark" />
          <TextField label={useTxtData.table.TotalCount} source="TotalCount" />
          <TextField label={useTxtData.table.TotalPrice} source="TotalPrice" />
          <FunctionField label={useTxtData.table.PayStyle} render={(record) => {
            return (
              <span style={{ color: 'red' }}>{record['Unlinepay'] ? "货到付款" : "在线支付"}</span>
            )
          }} />
          <TextField label={useTxtData.table.Discount} source="Discount" />
          <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
          <TextField label={useTxtData.table.PayTime} source="PayTime" />
          <TextField label={useTxtData.table.ReceiptTime} source="ReceiptTime" />
          <TextField label={useTxtData.table.FinishTime} source="FinishTime" />
          <TextField label={useTxtData.table.CancelTime} source="CancelTime" />
        </Tab>
        <Tab label="订单商品">
          <OrderProductList {...props} />
        </Tab>
        <Tab label="订单支付">
          <OrderPayList  {...props} />
        </Tab>
        <Tab label="订单日志">
          <OrderLogs  {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

// ////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////

// //编辑页面
// export const OrderEdit = (props) => {
//   const PostEditActions = (props) => {
//     const { basePath } = props; const redirect = useRedirect();
//     return (
//       <TopToolbar {...props}>
//         <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
//           <ArrowBack />
//         </Button>
//       </TopToolbar>
//     );
//   };
//   const PostEditToolbar = (props) => {
//     return (
//       <Toolbar {...props}>
//         <SaveButton />
//       </Toolbar>
//     );
//   };
//   return (
//     <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
//       <MyForm Edit={true} toolbar={<PostEditToolbar />} />
//     </Edit>
//   );
// };

// ////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////

// //创建页面
// export const OrderCreate = (props) => {
//   const PostCreateActions = (props) => {
//     const { basePath } = props; const redirect = useRedirect();
//     return (
//       <TopToolbar {...props}>
//         <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
//           <ArrowBack />
//         </Button>
//       </TopToolbar>
//     );
//   };
//   const PostCreateToolbar = (props) => {
//     const { basePath } = props;
//     return (
//       <Toolbar {...props}>
//         <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
//         <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
//       </Toolbar>
//     );
//   };

//   return (
//     <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
//       <MyForm Create={true} toolbar={<PostCreateToolbar />} />
//     </Create>
//   );
// };

// ////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////
// //Create和Edit公用表单
// const MyForm = (props) => {
//   let { Edit, Create } = props;
//   return (
//     <SimpleForm {...props} >
//       {/* <TextInput source="Name" /> */}
//       {/* <NumberInput source="OrderNum" /> */}
//       <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
//       <TextInput label={useTxtData.table.OrderNumber} source="OrderNumber" validate={[required()]} />
//       <TextInput label={useTxtData.table.OrderStatus} source="OrderStatus" validate={[required()]} />
//       <TextInput label={useTxtData.table.UserId} source="UserId" validate={[required()]} />
//       <TextInput label={useTxtData.table.Name} source="Name" validate={[required()]} />
//       <TextInput label={useTxtData.table.Mobile} source="Mobile" validate={[required()]} />
//       <TextInput label={useTxtData.table.Province} source="Province" validate={[required()]} />
//       <TextInput label={useTxtData.table.City} source="City" validate={[required()]} />
//       <TextInput label={useTxtData.table.Area} source="Area" validate={[required()]} />
//       <TextInput label={useTxtData.table.Addr} source="Addr" validate={[required()]} />
//       <TextInput label={useTxtData.table.LogRemark} source="LogRemark" validate={[required()]} />
//       <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} />
//       <TextInput label={useTxtData.table.PayTime} source="PayTime" validate={[required()]} />
//       <TextInput label={useTxtData.table.ReceiptTime} source="ReceiptTime" validate={[required()]} />
//       <TextInput label={useTxtData.table.FinishTime} source="FinishTime" validate={[required()]} />
//       <TextInput label={useTxtData.table.CancelTime} source="CancelTime" validate={[required()]} />
//       <TextInput label={useTxtData.table.Discount} source="Discount" validate={[required()]} />
//     </SimpleForm>
//   );
// }