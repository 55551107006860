import { apiUrl } from './global';

const authProvider = {
    login: ({ username, password }) => {
        return fetch(apiUrl + '/User/AdminLogin', {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            body: `username=${username}&password=${password}`
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((result) => {
                if (result.Code === 1) {
                    console.log('login success');
                    localStorage.setItem('token', result.Result.Token);
                }
                else {
                    console.log('login fail');
                    throw new Error(result.Message);
                }
            });
    },
    logout: () => {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('token');
            resolve();
        });
        // return fetch(apiUrl + '/User/AdminSignOut', {
        //     method: 'POST',
        //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
        //     body: `token=${localStorage.getItem('token')}`
        // })
        //     .then(response => {
        //         if (response.status < 200 || response.status >= 300) {
        //             throw new Error(response.statusText);
        //         }
        //         return response.json();
        //     })
        //     .then((result) => {
        //         if (result.Code === 1) {
        //             console.log('logout success');
        //             localStorage.removeItem('token');
        //         }
        //         else {
        //             console.log('logout fail');
        //             throw new Error(result.Message);
        //         }
        //     });
    },
    checkAuth: () => localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject(),
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        let token = localStorage.getItem('token');
        //console.log('token', token)

        //解密JWT Token
        let decodeToken = JSON.parse(decodeURIComponent(atob(token.split('.')[1].replace('-', '+').replace('_', '/')).split('').map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join('')));
        //console.log('decodeToken', decodeToken)

        const role = decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        return Promise.resolve(role);
    },
    getIdentity: () => {
        try {
            let token = localStorage.getItem('token');
            //console.log('token', token)

            //解密JWT Token
            let decodeToken = JSON.parse(decodeURIComponent(atob(token.split('.')[1].replace('-', '+').replace('_', '/')).split('').map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join('')));

            const userdata = JSON.parse(decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']);
            const id = decodeToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'];
            const fullName = userdata.NickName ?? userdata.Account;
            const avatar = userdata.Avatar;
            //设置ID 名称 头像
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    }
};

export default authProvider;